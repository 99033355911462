/* You can add global styles to this file, and also import other style files */

.ps {
    position: fixed;
    z-index: 999;
    height: 2em;
    width: 2em;
    overflow: show;
    margin: auto;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
}

.hide {
    position: fixed;
    top: 0;
    left: 0;
    background: rgb(255, 253, 253);
    opacity: 0.5;
    z-index: 998;
    height: 100%;
    width: 100%;
}

a{
    cursor: pointer;
}

.cursor{
    cursor: pointer;
}